<template>
    <div>
        <!-- 格栅背景 -->
        <div class="grilleBackground">


            <div class="title">
                <div class="logo">
                    <img src="@/assets/logo.png" class="logoimg" alt="">
                    <div>品呱呱</div>
                </div>

                <div class="topTow">
                    <div class="anchor" v-scroll-to="'#section1'">软件介绍</div>
                    <div class="anchor" v-scroll-to="'#section2'">共享展厅</div>
                    <div class="anchor" v-scroll-to="'#section3'">系统端口</div>
                </div>
                <div class="topRight">
                    <div class="anchor"  v-scroll-to="'#section4'">
                        服务与保障
                    </div>
                    <a class="anchor" style="color: #FFFFFF;text-decoration: none;" href="https://pinguagua.cn/download/#/">
                            软件下载
                        </a>
                    <div class="describe">
                        以科技赋能装企
                    </div>
                </div>
            </div>

            <!-- 内容区域 -->
            <div class="content">

                <!-- 头部导航栏下面的内容1 -->
                <div class="introduceBack">


                    <div class="content">

                        <div class="introduce1Title" id="section1">
                            装企可视化管理系统
                        </div>
                        <div class="describe">
                            高智能,全流程化,多段无缝流转
                        </div>
                        <div class="equipmentImgList">
                            <img src="@/assets/phone.png" class="phone" alt="">
                            <img src="@/assets/TV.png" class="TV" alt="">
                            <img src="@/assets/pad.png" class="pad" alt="">
                        </div>





                    </div>
                </div>




            </div>

            <img class="grilleBackgroundImg" src="@/assets/grilleBackground.png" alt="">

        </div>

        <div class="companyIntroduce">
            <div class="content">
                <!-- 介绍 -->
                <div class="explain">
                    &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; 深耕装修行业，历经6年时间研发，为装企做信息化转型提供助力。
                    采用最新的云端技术，将企业员工纳入到标准化的管理流程中，打通供应链体系，实现让装企、业主、供应商三方无缝对接，让整个流程可视化，方便工地管理，提升工地品质。<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本系统依托手机端APP，并将电视端APP、微信小程序、以及电脑端，多端融合，形成各个端口使用的便捷性。大大提高装企的管理效率，简化装修流程，缩短产业链条，减员增效。<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;系统主要适用于家装、软装等装饰工程相关领域。
                </div>
                <!-- 公司视频环境 -->
                <div class="videoBox">
                    <video controls="controls" class="environmentalDisplay" src="@/assets/video.mp4"></video>
                </div>
                <!-- 共享展厅模式 -->
                <div class="sharedExhibitionHall" id="section2">
                    <div class="top">
                        <div class="title">
                            共享展厅模式
                        </div>
                        <div class="title2">
                            装企可视化管理系统
                        </div>
                    </div>


                    <div class="introductionOfExhibitionHall">
                        <div class="left">
                            <div class="boldText">
                                装修公司
                            </div>
                            <div class="advantages">
                                省场地费用
                            </div>
                            <div class="advantages">
                                省装修费用
                            </div>
                            <div class="advantages">
                                更大的空间
                            </div>
                        </div>
                        <div class="center">
                            <img src="@/assets/exhibitionHall.png" class="exhibitionHallimg" alt="">
                            <div class="imgName">实体样板房展厅</div>
                        </div>
                        <div class="right">
                            <div class="boldText">
                                建材商
                            </div>
                            <div class="advantages">
                                省场地费用
                            </div>
                            <div class="advantages">
                                省装修费用
                            </div>
                            <div class="advantages">
                                面对更多的客户
                            </div>
                        </div>
                    </div>


                </div>
                <!-- 无界导购空间 -->
                <div class="sharedExhibitionHall">
                    <div class="top">
                        <div class="title">
                            无界空间导购
                        </div>
                        <div class="title2">
                            装企可视化管理系统
                        </div>
                    </div>


                    <div class="introductionOfExhibitionHall">
                        <div class="left">
                            <div class="boldText">
                                在家就能逛遍 <br />
                                全国品牌展厅

                            </div>

                        </div>
                        <div class="center1">

                            <img src="@/assets/camera.png" class="camera" alt="">
                            <img src="@/assets/exhibitionRoom.png" class="exhibitionHallimg" alt="">
                            <div class="imgName">实体软硬装展厅</div>
                        </div>
                        <div class="right">
                            <div class="advantages">
                                通过在线导购形式，业主在<br>
                                家里就可以选择观看各个品<br>
                                牌的展厅，然后选择重点款<br>
                                式进行更详细了解。
                            </div>

                        </div>
                    </div>


                </div>
                <!-- 我们能做什么 -->
                <div class="doWhat">
                    <div class="top">
                        <div class="title">
                            我们能做什么
                        </div>
                        <div class="title2">
                            装企可视化管理系统
                        </div>
                    </div>

                    <div class="imgLeft">
                        <div class="left">
                            <div class="leftTitle">
                                管客户
                            </div>
                            <img src="@/assets/manageCustomers.png" class="leftImg" alt="">
                        </div>
                        <div class="right">
                            <div class="item">※怎么找到客户</div>
                            <div class="item">※怎么搞定客户</div>
                            <div class="item">※怎么服务好客户</div>
                            <div class="item">※怎么让客户成为我们的人</div>
                        </div>
                    </div>

                    <div class="imgLeft">
                        <div class="right">
                            <div class="item">※公司营销体系标准化</div>
                            <div class="item">※公司服务流程标准化</div>
                            <div class="item">※工地管理标准化</div>
                            <div class="item">※财务流程，高效,可观</div>
                        </div>

                        <div class="left">
                            <div class="leftTitle">
                                管公司
                            </div>
                            <img class="leftImg" src="@/assets/manageCompany.png" alt="">
                        </div>

                    </div>
                    <div class="imgLeft">
                        <div class="left">
                            <div class="leftTitle">
                                管材料商
                            </div>
                            <img class="leftImg" src="@/assets/manageSupplier.png" alt="">
                        </div>
                        <div class="right">
                            <div class="item">※非标订单在线生成，告别繁琐流程</div>
                            <div class="item">※订单支付可按约定流程支付</div>
                            <div class="item">※在线收货确认，简化流程</div>
                            <div class="item">※订单流转清晰可见</div>
                        </div>



                    </div>
                </div>
                <!-- 系统端口介绍 -->
                <div class="introductionOfSystemPorts" id="section3">
                    <div class="top">
                        <div class="title">
                            系统端口介绍
                        </div>
                        <div class="title2">
                            装企可视化管理系统
                        </div>
                    </div>
                    <div class="equipmentItem">
                        <div class="itemImg">
                            <div class="equipmentName">
                                电视端APP
                            </div>
                            <img src="@/assets/TV.png" class="TVImg" alt="">
                        </div>
                        <div class="itemText">
                            大屏更直观，提升品牌形象<br>

                            模块设计，可添加个性化定制内容<br>

                            丰富的供应链产品选择，软硬装一站式打通
                        </div>
                    </div>
                    <!-- 微信小程序 -->
                    <div class="equipmentItem">

                        <div class="itemText">
                            业主无需下载，零成本沟通<br>
                            轻松参与装修全过程<br>
                            摄像头检查工地

                        </div>
                        <div class="itemImg">
                            <div class="equipmentName">
                                微信小程序
                            </div>
                            <div class="imgList">
                                <img src="@/assets/miniProgram1.png" class="miniProgramImg" alt="">
                                <img src="@/assets/miniProgram2.png" class="miniProgramImg" alt="">
                                <img src="@/assets/miniProgram3.png" class="miniProgramImg" alt="">
                            </div>
                        </div>
                    </div>
                    <!-- 手机端APP  -->
                    <div class="equipmentItem">
                        <div class="itemImg">
                            <div class="equipmentName">
                                手机端App
                            </div>
                            <div class="imgList">
                                <img src="@/assets/phone1.png" class="phoneImg" alt="">
                                <img src="@/assets/phone2.png" class="phoneImg" alt="">

                            </div>
                        </div>
                        <div class="itemText">
                            <div class="subheading">
                                移动化办公
                            </div>
                            传统的装修企业管理软件，主要的功能都在 <br> 电脑端，我们通过行业资深从业者的经验，<br> 提炼出一套可以直接在手机上完成的管理软 <br> 件，真正做到管理层随时随地管理。

                        </div>

                    </div>
                    <!-- Pad端App  -->
                    <div class="equipmentItem">
                        <div class="itemText">

                            业务员谈单便利<br>
                            展示过程专业<br>
                            提升签单成功率


                        </div>
                        <div class="itemImg">
                            <div class="equipmentName">
                                Pad端App
                            </div>

                            <img src="@/assets/pad.png" class="padImg" alt="">

                        </div>


                    </div>

                </div>

                <!-- 4段文字介绍 -->
                <div class="textImg">
                    <div class="top">
                        <div class="title">
                            装企为什么要用管理系统
                        </div>
                        <div class="title2">
                            帮助装修行业做信息化转型
                        </div>
                    </div>

                    <div class="table">

                        <table class="demo">

                            <thead>
                                <tr>
                                    <th></th>
                                    <th><span>传统装企</span></th>
                                    <th><span>信息化管理装企</span><br>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="td1">数据采集</td>
                                    <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;使用人工统计，失误率高，效率地下</td>
                                    <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;背部各个岗位数据，集中整合，不会遗漏犯错，在线数据统计和分析，一目了然</td>
                                </tr>
                                <tr>
                                    <td class="td1">客户管理</td>
                                    <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;内部员工流失，导致客户资源流失，对接繁杂</td>
                                    <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;内部员工流失，切断系统即可保留客户资源，减少不必要的损失</td>
                                </tr>
                                <tr>
                                    <td class="td1">工程管理</td>
                                    <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 不能及时发现问题，项目监理每天疲于奔命，工地质量控制困难</td>
                                    <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;标准的可视化的工地管理，及时发现问题，解决效率高，减员增效，一个监理可以管理更多的工地
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td1">工作协同</td>
                                    <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;传统登记模式，信息流转随意化，造成大量内耗</td>
                                    <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;同一平台，统一操作，实时同步信息，凭证留存，责任到人</td>
                                </tr>
                                <tr>
                                    <td class="td1">供应链赋能</td>
                                    <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;没有议价资本，采购成本高且对接成本高</td>
                                    <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;丰富的供应链体系，可形成平台对品牌商的议价能力，赋能装企获得更高利润</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- 用系统后的优势 -->
                <div class="advantages">
                    <div class="top">
                        <div class="title">
                            用系统的优势
                        </div>

                    </div>
                    <div class="textList">
                        <div> 1.让装企实现可视化、流程化、系统化管理，提升团队效率。</div>

                        <div> 2.提升客户转化率和转介绍</div>

                        <div>3.装企获客的有效工具，</div>

                        <div> 4.完善和丰富装企的供应链体系</div>

                        <div> 5.帮助装企实现工地管理标准化，提升工地品质。</div>

                        <div>6.帮助装企实现完整的客户评价体系，和完善的售后服务体系</div>


                    </div>
                </div>

                <!-- 为何选择我们  -->
                <div class="selection">
                    <div class="top">
                        <div class="title">
                            为何选择我们
                        </div>
                        <div class="title2">
                            核心价值
                        </div>
                    </div>
                    <div class="textList">
                        <div class="textItem">
                            <div class="textTitle">
                                1 效率的提升
                            </div>
                            <div class="descriptionOfAdvantages">
                                可视化、流程化的管理模式，提升整体工作效率。减员增效，等同于相同的人能创造更大的价值。
                            </div>
                        </div>

                        <div class="textItem">
                            <div class="textTitle">
                                2 使用的便捷
                            </div>
                            <div class="descriptionOfAdvantages">
                                所有角色通过权限控制，以及多端app的融合，业主以及工人无需下载软件，降低软件使用的学习成本，更利用内部推广使用，让整个流程的运作更加便捷。
                            </div>
                        </div>
                        <div class="textItem">
                            <div class="textTitle">
                                3 数据分析
                            </div>
                            <div class="descriptionOfAdvantages">
                                强大的数据整合能力，一台手机就能解决财务收支、成本核算难等问题，管理层随时随地掌控公司的运营健康度，能做出更精准的工作计划。
                            </div>
                        </div>
                        <div class="textItem">
                            <div class="textTitle">
                                4 创新性可视化工地管理
                            </div>
                            <div class="descriptionOfAdvantages">
                                通过在线摄像头，能随时监管工地施工进度，工人到位情况，各个流程和岗位分配明确，降低管理难度。
                            </div>
                        </div>
                        <div class="textItem">
                            <div class="textTitle">
                                5 品牌升级
                            </div>
                            <div class="descriptionOfAdvantages">
                                专属的公司个性化定制界面，提升公司形象，促进业主对公司的信任感，提升签单成功率。
                            </div>
                        </div>
                        <div class="textItem">
                            <div class="textTitle">
                                6 利润增长
                            </div>
                            <div class="descriptionOfAdvantages">
                                更高效的工地管理，和人员协同，减少传统装企在工地管理过程中因为信息化不完善造成的不必要损耗。不仅节省了成本，还能有更好的精力去服务更多的业主。
                            </div>
                        </div>

                        <div class="textItem">
                            <div class="textTitle">
                                7 供应链赋能
                            </div>
                            <div class="descriptionOfAdvantages">
                                强大完善的供应链体系，通过平台的议价能力，能为装企带来更物美价廉的产品，提升利润，也能满足业主的多元需求。
                            </div>
                        </div>
                        <div class="textItem">
                            <div class="textTitle">
                                8 业主满意度提升
                            </div>
                            <div class="descriptionOfAdvantages">
                                业主无需下载软件，就能全程在线参与施工进度、工地材料、验收、评分等过程，省去舟车劳顿带来的烦恼，还能收到一个理想的小家。而且装修体验得到非常高的提升，口口相传，通过转介绍获取更多优质客源。
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 服务与保障 -->
                <div class="selection" id="section4">
                    <div class="top">
                        <div class="title">
                            服务与保障
                        </div>
                     

                        <div class="textList">
                            <div class="textItem">
                                <div class="textTitle">
                                    培训服务
                                </div>
                                <div class="descriptionOfAdvantages">
                                    公司专业客服和业务人员进行培训，学习成本很低，有问题随时在线回复，定期回访，落地速度快，体验无烦恼。
                                </div>
                            </div>
                            <div class="textItem">
                                <div class="textTitle">
                                    直播教学
                                </div>
                                <div class="descriptionOfAdvantages">
                                    公司企业号每天定时直播，在线解决问题。零成本零距离的沟通交流。
                                </div>
                            </div>
                            <div class="textItem">
                                <div class="textTitle">

                                    学习指南
                                </div>
                                <div class="descriptionOfAdvantages">
                                    清晰简便的使用说明，基本上手就懂，如果没有时间学习沟通，能通过使用说明快速解决软件使用问题。
                                </div>
                            </div>
                            <div class="textItem">
                                <div class="textTitle">

                                    产品升级
                                </div>
                                <div class="descriptionOfAdvantages">
                                    专业的产品研发团队，行业资深从业大佬，根据市场变化快速迭代产品。
                                </div>
                            </div>
                            <div class="textItem">
                                <div class="textTitle">
                                    安全保障
                                </div>
                                <div class="descriptionOfAdvantages">
                                    所以数据存在阿里云，自动备份生产，不用担心数据丢失问题
                                </div>
                            </div>
                            <div class="textItem">
                                <div class="textTitle">
                                    供应链服务
                                </div>
                                <div class="descriptionOfAdvantages">
                                    仅为会员提供的优质供应链体系，花更少的钱办更大的事。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>


        <div class="footer">
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=2022006662"
                class="recordNumber">备案号:赣ICP备2022006662号-1</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'indexPage',
    data() {
        return {

        }
    },
    methods:{
        go(num){
            if(num==1){
                // 软件接受

            }
        }
    },



}
</script>

<style lang="less" scoped>
.top {
    text-align: center;
    margin: 30px 0 40px 0;

    .title {
        font-size: 40px;
        font-weight: 540;
    }

    .title2 {
        font-size: 26px;
    }
}

.grilleBackground {
    position: relative;

    .title {
        width: 100%;
        height: 100px;
        background-color: #192528;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #FFFFFF;
        padding-left: 14%;
        padding-right: 14%;
        box-sizing: border-box;

        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            font-weight: 700;

            .logoimg {
                width: 50px;
                height: 50px;
                margin-right: 20px;
            }
        }

        .topTow {
            display: flex;

            font-size: 16px;
            font-weight: 300;

            .anchor {
                margin-right: 20px;
                cursor: pointer;
            }
        }

        .topRight {
            // margin-left: 100px;
            font-weight: 300;
            display: flex;
            align-items: center;

            .anchor {
                font-size: 16px;
                margin-right: 25px;
                z-index: 10000;
                cursor: pointer;
            }

            .describe {
                font-size: 30px;
            }
        }
    }
}

.grilleBackgroundImg {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
    height: 100%;

}


.content {
    //内容区域样式
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .introduceBack {
        background-image: url(@/assets/introduce1Backguound.png);
    }

    .content {
        width: 60%;
        height: 506px;
        margin: 0 auto;
        box-sizing: border-box;
        color: #FFFFFF;

        .introduce1Title {
            padding-top: 78px;
            box-sizing: border-box;
            font-size: 40px;
            font-weight: 700;

        }

        .describe {
            margin-top: 5px;
            font-size: 26px;
            font-weight: 300;
        }

        .introduce {
            margin-top: 400px;
        }

        .equipmentImgList {
            // width: 100%;
            margin-top: 100px;
            display: flex;
            z-index: 99 !important;
            align-items: flex-end;
            position: absolute;
            bottom: -150px;

            .phone {
                width: 180px;
                height: 380px;
                margin-right: 5px;
            }

            .TV {
                width: 550px;
                height: 380px;
                margin-right: 5px;
            }

            .pad {
                width: 300px;
                height: 190px;
            }

        }
    }
}

.companyIntroduce {
    margin-top: 200px;
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    font-weight: 300;

    .content {
        width: 60%;

        .explain {
            padding: 0 60px 0 60px;
            font-size: 18px;
            line-height: 30px;



        }

        .videoBox {
            margin: 20px 0 0px 0;

            .environmentalDisplay {
                width: 100%;
                height: 530px;
            }
        }

        // 共享展厅样式
        .sharedExhibitionHall {
            .top {
                text-align: center;
                margin: 30px 0 40px 0;

                .title {
                    font-size: 40px;
                    font-weight: 540;
                }

                .title2 {
                    font-size: 26px;
                }
            }

            .introductionOfExhibitionHall {
                margin-top: 50px;
                margin-bottom: 60px;
                display: flex;
                align-items: center;
                justify-content: space-around;

                width: 100%;

                font-size: 18px;

                .boldText {
                    font-weight: 550;

                }

                .left {
                    width: 180px;
                }

                .advantages {
                    font-size: 16px;
                }

                .center {
                    position: relative;

                    .exhibitionHallimg {
                        width: 365px;
                        height: 216px;
                    }

                    .imgName {
                        position: absolute;
                        left: 0;
                        bottom: 30px;
                        font-weight: 550;
                        font-size: 16px;
                    }
                }

                // 无界导购空间中间
                .center1 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .camera {
                        width: 50px;
                        height: 50px;
                    }

                    .imgName {
                        margin-top: 30px;
                        font-weight: 550;
                        font-size: 16px;
                    }
                }
            }
        }

        .doWhat {
            .top {
                text-align: center;
                margin: 80px 0 80px 0;

                .title {
                    font-size: 40px;
                    font-weight: 540;
                }

                .title2 {
                    font-size: 26px;
                }
            }

            .imgLeft {
                display: flex;
                justify-content: space-around;
                align-content: center;
                margin-bottom: 50px;

                .left {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .leftTitle {
                        font-size: 26px;
                        margin-bottom: 30px;
                        font-weight: 550;
                    }

                    .leftImg {
                        width: 160px;
                        height: 140px;
                    }
                }

                .right {
                    font-size: 18px;
                    display: flex;
                    flex-direction: column;
                    line-height: 32px;
                    justify-content: center;

                }

            }

        }

        // 系统端口
        .introductionOfSystemPorts {
            .equipmentItem {
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-top: 100px;

                .itemImg {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .equipmentName {
                        font-size: 26px;
                        font-weight: 550;
                        margin-bottom: 50px;

                    }

                    .imgList {
                        display: flex;

                        .miniProgramImg {
                            margin-right: 15px;
                            height: 300px;
                        }

                        .phoneImg {
                            margin-right: 15px;
                            height: 300px;
                        }

                    }

                    .TVImg {
                        width: 400px;
                        height: 250px;
                    }

                    .padImg {
                        height: 200px;
                    }
                }

                .itemText {
                    .subheading {
                        font-size: 34px;
                        margin-bottom: 20px;
                    }

                    font-size: 18px;
                    font-weight: 300;
                    line-height: 36px;
                }
            }
        }

        // 4段文字接受
        .textImg {
            margin-top: 50px;
        }

        // 表格
        .demo {
            border: 1px solid #000000;
            border-collapse: collapse;
            padding: 5px;

            .td1 {
                width: 100px;
            }
        }

        .demo th {
            border: 1px solid #000000;
            padding: 5px;
            background: #FFFFFF;
        }

        .demo td {
            border: 1px solid #000000;
            padding: 10px;
        }

        // 优势
        .advantages {
            .textList {
                display: flex;
                flex-direction: column;
                // justify-content: center;
                // align-items: center;
                text-align: left;
                font-weight: 300;
                line-height: 42px;
            }
        }

        // 选择
        .selection {
           
            .textList {
                text-align: left;
                .textItem {
                    margin-top: 20px;

                    .textTitle {
                        font-size: 18px;
                        font-weight: 550;
                    }

                    .descriptionOfAdvantages {
                        font-size: 16px;
                      
                        margin-top: 10px;
                    }
                }
            }
        }


    }

}

.footer {
    background-color: #000000;
    width: 100%;
    height: 100px;




    .recordNumber {
        color: #CCCCCC;
        margin-left: 30px;
        line-height: 100px;
    }
}
</style>