import Vue from 'vue'
import Router from 'vue-router'
// 1. 定义（路由）组件。
import indexPage from '../pages/indexPage/indexPage.vue'

Vue.use(Router)

// 2. 定义路由
// 每个路由应该映射一个组件。 其中"component" 可以是
export default new Router({
    routes: [
        {
            path: '/',
            name: 'indexPage',
            component: indexPage
        },
  
        
    ]
})